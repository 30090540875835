import React, { useRef, useMemo, useState, useEffect } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import * as THREE from "three";
import { motion, useInView } from "framer-motion";
import { useTranslation } from 'react-i18next';

const colors = [
  "#3498db", // Bright Blue
  "#2ecc71", // Emerald Green
  "#9b59b6", // Amethyst Purple
  "#e74c3c", // Soft Red
  "#f39c12", // Bright Orange
  "#1abc9c", // Turquoise
  "#d35400", // Pumpkin Orange
  "#34495e", // Wet Asphalt (darker blue for contrast)
  "#16a085", // Green Sea
  "#8e44ad", // Wisteria Purple
  "#2980b9", // Belize Hole Blue
  "#c0392b", // Pomegranate Red
];

const lighterColors = ["#CEFA0A"];

const Cube = ({ position, color, isActive, onClick }) => {
  const meshRef = useRef();
  const [isAnimating, setIsAnimating] = useState(false);
  const animationProgress = useRef(0);
  const phase = useMemo(() => Math.random() * Math.PI * 2, []);
  const amplitude = useMemo(() => 0.1 + Math.random() * 0.3, []);
  const [currentColor, setCurrentColor] = useState(color);
  const originalColor = useRef(color);
  const newColor = useRef(new THREE.Color(color));

  useEffect(() => {
    originalColor.current = color;
  }, [color]);

  useFrame((state, delta) => {
    const baseCubeLength = 3;
    let currentLength = baseCubeLength;
    let offset = 0;

    if (isAnimating) {
      animationProgress.current += delta;
      const animationDuration = 0.5;

      if (animationProgress.current < animationDuration) {
        const maxLengthIncrease = 2;
        const progress = animationProgress.current / animationDuration;
        const lengthIncrease = Math.sin(progress * Math.PI) * maxLengthIncrease;
        currentLength = baseCubeLength + lengthIncrease;
        offset = lengthIncrease / 2;

        // Color transition
        const colorProgress = Math.sin(progress * Math.PI);
        setCurrentColor(
          new THREE.Color().lerpColors(
            originalColor.current,
            newColor.current,
            colorProgress
          )
        );
      } else {
        setIsAnimating(false);
        animationProgress.current = 0;
        originalColor.current = newColor.current;
        setCurrentColor(newColor.current);
      }
    }

    meshRef.current.geometry.dispose();
    meshRef.current.geometry = new THREE.BoxGeometry(0.5, 0.5, currentLength);

    const baseZPosition = Math.sin(state.clock.elapsedTime + phase) * amplitude;
    meshRef.current.position.z = baseZPosition + offset;
  });

  const handleClick = (event) => {
    event.stopPropagation();
    if (isActive && !isAnimating) {
      setIsAnimating(true);
      animationProgress.current = 0;
      // Choose a new random color from the palette
      const randomColor = lighterColors[0];
      newColor.current = new THREE.Color(randomColor);
      onClick();
    }
  };

  return (
    <mesh ref={meshRef} position={position} onClick={handleClick}>
      <boxGeometry args={[1, 1, 3]} />
      <meshStandardMaterial color={currentColor} />
    </mesh>
  );
};

const CubeWall = () => {
  const wallWidth = 30;
  const wallHeight = 7;
  const [activeCubeIndex, setActiveCubeIndex] = useState(null);
  const groupRef = useRef();
  const raycaster = new THREE.Raycaster();
  const { camera, mouse } = useThree();

  const cubes = useMemo(() => {
    const temp = [];
    for (let i = 0; i < wallWidth; i++) {
      for (let j = 0; j < wallHeight; j++) {
        temp.push({
          position: [
            i / 2 - wallWidth / 2 + 0.5 + 8,
            j / 2 - 4 - wallHeight / 2 + 0.5,
            0,
          ],
          color: new THREE.Color(
            colors[Math.floor(Math.random() * colors.length)]
          ),
        });
      }
    }
    return temp;
  }, []);

  useFrame(() => {
    raycaster.setFromCamera(mouse, camera);
    const intersects = raycaster.intersectObjects(groupRef.current.children);
    if (intersects.length > 0) {
      const firstIntersectedObject = intersects[0].object;
      const index = groupRef.current.children.indexOf(firstIntersectedObject);
      setActiveCubeIndex(index);
    } else {
      setActiveCubeIndex(null);
    }
  });

  const handleCubeClick = () => {
    console.log("Cube clicked!", activeCubeIndex);
  };

  return (
    <group ref={groupRef}>
      {cubes.map((cube, index) => (
        <Cube
          key={index}
          {...cube}
          isActive={index === activeCubeIndex}
          onClick={handleCubeClick}
        />
      ))}
    </group>
  );
};

const InstructionText = () => {
  return (
    <div className="absolute bottom-4 left-0 right-0 text-center">
      <motion.p
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 1, duration: 0.5 }}
        className="text-lg font-semibold text-gray-800 bg-white bg-opacity-70 px-4 py-2 rounded-full inline-block"
      >
        Try touching the cubes🔥
      </motion.p>
    </div>
  );
};

const Scene = () => (
  <>
    <ambientLight intensity={0.8} />
    <pointLight position={[10, 10, 10]} intensity={1000} />
    <CubeWall />
  </>
);

const SocialButton = ({ href, icon, color, title }) => {
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.5 });

  return (
    <motion.a
      ref={ref}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={`group flex items-center justify-between px-6 py-3 rounded-lg ${color} text-white shadow-md transition-all duration-300 overflow-hidden relative pointer-events-auto`}
      initial={{ opacity: 0, y: 20 }}
      animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
      transition={{ duration: 0.3, ease: "linear" }}
      whileHover={{ scale: 1.05, boxShadow: "0 10px 20px rgba(0,0,0,0.2)" }}
      whileTap={{ scale: 0.95 }}
      variants={{
        initial: { opacity: 0, y: 20 },
        animate: { opacity: 1, y: 0 },
        hover: { scale: 1.05, boxShadow: "0 10px 20px rgba(0,0,0,0.2)" },
        tap: { scale: 0.95 },
      }}
      transition={{
        default: { duration: 0.3, ease: "linear" },
        scale: { duration: 0.1, ease: "easeInOut" },
      }}
    >
      <div className="flex items-center pointer-events-none">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="mr-3 transition-transform duration-300 group-hover:rotate-12"
        >
          <path d={icon} />
        </svg>
        <span className="text-lg font-semibold">{title}</span>
      </div>
      <div className="w-8 h-8 rounded-full bg-white bg-opacity-20 flex items-center justify-center transition-all duration-300 group-hover:w-14 pointer-events-auto">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M5 12h14M12 5l7 7-7 7" />
        </svg>
      </div>
      <div className="absolute inset-0 bg-white opacity-0 group-hover:opacity-10 transition-opacity duration-300 pointer-events-none" />
    </motion.a>
  );
};

const ContactSection = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="relative w-full h-screen overflow-hidden">
      <Canvas camera={{ position: [0, -10, 6], fov: 50 }}>
        <Scene />
      </Canvas>

      <div className="absolute inset-0 flex flex-col items-center justify-center pointer-events-none">
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          className="text-5xl font-bold mb-10 text-black text-center"
        >
          {t("contactsTitle")}
        </motion.h2>

        <motion.div
          className="flex flex-col space-y-4 w-full max-w-md px-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.5 }}
        >
          <SocialButton
            href="https://github.com/yourusername"
            icon="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12"
            color="bg-gray-800"
            title="GitHub"
          />
          <SocialButton
            href="https://linkedin.com/in/yourusername"
            icon="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"
            color="bg-blue-600"
            title="LinkedIn"
          />
          <SocialButton
            href="https://twitter.com/yourusername"
            icon="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"
            color="bg-blue-400"
            title="Twitter"
          />
          <SocialButton
            href="mailto:your.email@example.com"
            icon="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"
            color="bg-red-500"
            title="Email"
          />
        </motion.div>
      </div>
      <InstructionText />
    </div>
  );
};

export default ContactSection;
