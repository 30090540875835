import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitch = () => {
  const { i18n } = useTranslation();

  const toggleLanguage = () => {
    i18n.changeLanguage(i18n.language === 'en' ? 'it' : 'en');
  };

  const isItalian = i18n.language === 'it';

  return (
    <button
      onClick={toggleLanguage}
      className="fixed top-4 right-4 w-20 h-18 bg-gray-300 dark:bg-gray-600 rounded-full p-1 duration-300 ease-in-out"
    >
      <div
        className={`w-10 h-10 rounded-full shadow-md transform duration-300 ease-in-out flex items-center justify-center ${
          isItalian ? 'translate-x-8 bg-white' : 'bg-white'
        }`}
      >
        {isItalian ? (
          <span className="text-xl">🇮🇹</span>
        ) : (
          <span className="text-xl">🇬🇧</span>
        )}
      </div>
    </button>
  );
};

export default LanguageSwitch;