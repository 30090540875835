// App.js
import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import PresentationSection from './PresentationSection';
import ProjectsSection from './ProjectsSection';
import ContactSection from './ContactSection';
import LanguageSwitch from './elements/LanguageSwitch';

import { useTranslation } from 'react-i18next';

const DarkModeToggle = ({ darkMode, toggleDarkMode }) => (
  <button
          onClick={toggleDarkMode}
    className="fixed top-4 right-4 bg-gray-200 dark:bg-gray-800 text-gray-800 dark:text-gray-200 px-4 py-4 rounded-full shadow-lg hover:bg-gray-300 dark:hover:bg-gray-700 transition-colors z-50"
        >
          {darkMode ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-yellow-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-gray-800"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
              />
            </svg>
          )}
        </button>
);

const NextSectionButton = ({ title, onClick }) => (
  <AnimatePresence>
    {title !== "Home" && (
      <motion.div
        initial={{ y: 100 }}
        animate={{ y: 0 }}
        exit={{ y: 100 }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
        className="fixed bottom-0 left-0 right-0 flex justify-center z-50 px-4"
      >
        <button
          onClick={onClick}
          className="
            group relative overflow-hidden
            bg-blue-500 dark:bg-blue-700
            text-white px-8 py-3 rounded-t-xl
            shadow-lg hover:shadow-xl
            transition-all duration-300 ease-in-out
            text-sm sm:text-base font-semibold
            border-t-2 border-l-2 border-r-2 border-blue-300 dark:border-blue-500
          "
        >
          <span className="relative z-10">Next: {title}</span>
          <span className="absolute inset-0 bg-gradient-to-r from-blue-600 to-blue-700 dark:from-blue-700 dark:to-blue-800 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out"></span>
        </button>
      </motion.div>
    )}
  </AnimatePresence>
);

export default function App() {
  const { t, i18n } = useTranslation();
  const toggleLanguage = () => {
    i18n.changeLanguage(i18n.language === 'en' ? 'it' : 'en');
  };

  const [darkMode, setDarkMode] = useState(false);
  const [currentSection, setCurrentSection] = useState(0);
  const sectionRefs = useRef([]);

  const sections = [
    { id: 'presentation', title: 'Home', component: PresentationSection },
    { id: 'projects', title: 'Projects', component: ProjectsSection },
    { id: 'contact', title: 'Contact', component: ContactSection },
  ];

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);

  const toggleDarkMode = () => setDarkMode(!darkMode);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight / 3;

      for (let i = 0; i < sectionRefs.current.length; i++) {
        const section = sectionRefs.current[i];
        if (section && scrollPosition >= section.offsetTop && scrollPosition < (section.offsetTop + section.offsetHeight)) {
          setCurrentSection(i);
          break;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Call once to set initial state
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToNextSection = () => {
    const nextSectionIndex = (currentSection + 1) % sections.length;
    sectionRefs.current[nextSectionIndex].scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="bg-gray-100 dark:bg-gray-900 min-h-screen transition-colors duration-300">
      <LanguageSwitch></LanguageSwitch>
      {sections.map((section, index) => (
        <div key={section.id} ref={(el) => (sectionRefs.current[index] = el)}>
          <section.component />
        </div>
      ))}
      <NextSectionButton 
        title={sections[(currentSection + 1) % sections.length].title}
        onClick={scrollToNextSection}
      />
    </div>
  );
}