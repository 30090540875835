import React, { useState, useRef, useEffect } from "react";
import { motion, AnimatePresence, useInView } from "framer-motion";
import { useTranslation } from 'react-i18next';

const projects = [
  {
    id: 1,
    title: "Smartwatch",
    description: "A full-stack e-commerce solution with React and Node.js",
    fullDescription:
      "This project is a comprehensive e-commerce platform built using React for the frontend and Node.js for the backend. It features user authentication, product catalog, shopping cart functionality, and payment integration.This project is a comprehensive e-commerce platform built using React for the frontend and Node.js for the backend. It features user authentication, product catalog, shopping cart functionality, and payment integration.This project is a comprehensive e-commerce platform built using React for the frontend and Node.js for the backend. It features user authentication, product catalog, shopping cart functionality, and payment integration.This project is a comprehensive e-commerce platform built using React for the frontend and Node.js for the backend. It features user authentication, product catalog, shopping cart functionality, and payment integration.This project is a comprehensive e-commerce platform built using React for the frontend and Node.js for the backend. It features user authentication, product catalog, shopping cart functionality, and payment integration.This project is a comprehensive e-commerce platform built using React for the frontend and Node.js for the backend. It features user authentication, product catalog, shopping cart functionality, and payment integration.This project is a comprehensive e-commerce platform built using React for the frontend and Node.js for the backend. It features user authentication, product catalog, shopping cart functionality, and payment integration.",
    technologies: ["React", "Node.js", "Express", "MongoDB", "Stripe"],
    image: "gradient0.png",
    hasExternalLink: true,
    externalLink: "https://example.com/smartwatch",
  },
  {
    id: 2,
    title: "Restaurant Menu",
    description: "A Trello-like task management application",
    fullDescription:
      "This task management app allows users to create boards, lists, and cards to organize their projects. It includes features like drag-and-drop functionality, user collaboration, and real-time updates.",
    technologies: ["React", "Redux", "Firebase", "Material-UI"],
    image: "gradient1.png",
    hasExternalLink: false,
  },
  {
    id: 3,
    title: "Pepe",
    description: "A Trello-like task management application",
    fullDescription:
      "This task management app allows users to create boards, lists, and cards to organize their projects. It includes features like drag-and-drop functionality, user collaboration, and real-time updates.",
    technologies: ["React", "Redux", "Firebase", "Material-UI"],
    image: "gradient2.png",
    hasExternalLink: true,
    externalLink: "https://example.com/pepe",
  },
  {
    id: 4,
    title: "Task Management App",
    description: "A Trello-like task management application",
    fullDescription:
      "This task management app allows users to create boards, lists, and cards to organize their projects. It includes features like drag-and-drop functionality, user collaboration, and real-time updates.",
    technologies: ["React", "Redux", "Firebase", "Material-UI"],
    image: "gradient2.png",
    hasExternalLink: false,
  },
  {
    id: 5,
    title: "Helo",
    description: "A Trello-like task management application",
    fullDescription:
      "This task management app allows users to create boards, lists, and cards to organize their projects. It includes features like drag-and-drop functionality, user collaboration, and real-time updates.",
    technologies: ["React", "Redux", "Firebase", "Material-UI"],
    image: "gradient2.png",
    hasExternalLink: false,
  },
  {
    id: 5,
    title: "stuff",
    description: "A Trello-like task management application",
    fullDescription:
      "This task management app allows users to create boards, lists, and cards to organize their projects. It includes features like drag-and-drop functionality, user collaboration, and real-time updates.",
    technologies: ["React", "Redux", "Firebase", "Material-UI"],
    image: "gradient2.png",
    hasExternalLink: false,
  },
  {
    id: 6,
    title: "Amazing Project",
    image: "gradient2.png",
    fullDescription: "This is a full description of the amazing project...",
    technologies: ["React", "Node.js", "MongoDB"],
    hasExternalLink: true,
    externalLink: "https://example.com",
    githubLink: "https://github.com/username/repo",
  },
];

const ArrowIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 12H19M19 12L12 5M19 12L12 19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const ProjectCard = ({ project, onClick }) => {
  return (
    <motion.div
      className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden cursor-pointer w-full max-w-sm mx-auto"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.2 }}
      whileHover={{ scale: 1.03 }}
      onClick={() => onClick(project)}
    >
      <div className="relative h-48">
        <img
          src={project.image}
          alt={project.title}
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
      </div>
      <div className="p-6">
        <h3 className="text-xl font-bold mb-2 text-gray-800 dark:text-white">
          {project.title}
        </h3>
        <p className="text-sm text-gray-600 dark:text-gray-300 mb-4 line-clamp-2">
          {project.description}
        </p>
        <div className="flex flex-wrap gap-2 mb-4">
          {project.technologies.slice(0, 3).map((tech) => (
            <span
              key={tech}
              className="bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 px-2 py-1 rounded-full text-xs font-medium"
            >
              {tech}
            </span>
          ))}
          {project.technologies.length > 3 && (
            <span className="bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200 px-2 py-1 rounded-full text-xs font-medium">
              +{project.technologies.length - 3}
            </span>
          )}
        </div>
        <motion.div
          className="flex justify-end"
          whileHover={{ x: 5 }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
        >
          <span className="text-blue-500 dark:text-blue-400 inline-flex items-center">
            View Project <ArrowIcon />
          </span>
        </motion.div>
      </div>
    </motion.div>
  );
};


const ProjectModal = ({ project, onClose }) => {
  const scrollRef = useRef(null);
  const [isTopScrollable, setIsTopScrollable] = useState(false);
  const [isBottomScrollable, setIsBottomScrollable] = useState(true);

  useEffect(() => {
    const checkScrollable = () => {
      if (scrollRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
        setIsTopScrollable(scrollTop > 0);
        setIsBottomScrollable(scrollTop + clientHeight < scrollHeight);
      }
    };

    const scrollElement = scrollRef.current;
    if (scrollElement) {
      scrollElement.addEventListener('scroll', checkScrollable);
      window.addEventListener('resize', checkScrollable);
    }

    checkScrollable();

    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener('scroll', checkScrollable);
        window.removeEventListener('resize', checkScrollable);
      }
    };
  }, [project]);

  return (
    <motion.div
      className="fixed inset-0 h-full bg-black bg-opacity-60 flex items-center justify-center p-4 z-50 backdrop-blur-sm"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        className="bg-white dark:bg-gray-800 rounded-xl w-full max-w-3xl shadow-2xl overflow-hidden flex flex-col max-h-[80svh]"
        initial={{ scale: 0.9, opacity: 0, y: 20 }}
        animate={{ scale: 1, opacity: 1, y: 0 }}
        exit={{ scale: 0.9, opacity: 0, y: 20 }}
        transition={{ type: "spring", damping: 25, stiffness: 300 }}
      >
        <div className="relative">
          <img src={project.image} alt={project.title} className="w-full h-64 object-cover" />
          <motion.button
            className="absolute top-4 right-4 bg-white dark:bg-gray-800 rounded-full p-2 shadow-md hover:shadow-lg transition-shadow"
            onClick={onClose}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-600 dark:text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </motion.button>
        </div>

        <div className="px-8 pt-8">
          <h2 className="text-3xl font-bold mb-4 text-gray-800 dark:text-white">{project.title}</h2>
        </div>

        <div className="flex-grow flex flex-col min-h-0 relative">
          {isTopScrollable && (
            <div className="absolute z-40 top-0 left-0 right-0 h-8 bg-gradient-to-b from-white dark:from-gray-800 to-transparent pointer-events-none" />
          )}
          <div 
            ref={scrollRef}
            className="flex-grow overflow-y-auto"
          >
            <div className="px-8 pb-4">
              <p className="text-gray-700 dark:text-gray-300 leading-relaxed">
                {project.fullDescription}
              </p>
            </div>
          </div>
          {isBottomScrollable && (
            <div className="absolute z-40 bottom-0 left-0 right-0 h-8 bg-gradient-to-t from-white dark:from-gray-800 to-transparent pointer-events-none" />
          )}
        </div>

        <div className="p-8 pt-4">
          <div className="mb-4">
            <h3 className="text-xl font-semibold mb-3 text-gray-800 dark:text-white">Technologies Used:</h3>
            <div className="flex flex-wrap gap-2">
              {project.technologies.map((tech) => (
                <span key={tech} className="bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 px-3 py-1 rounded-full text-sm font-medium">
                  {tech}
                </span>
              ))}
            </div>
          </div>
          <div className="flex gap-4">
            {project.hasExternalLink && (
              <motion.a
                href={project.externalLink}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center bg-gradient-to-r from-blue-500 to-blue-600 text-white px-6 py-3 rounded-lg font-semibold hover:from-blue-600 hover:to-blue-700 transition-all shadow-md hover:shadow-lg"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                </svg>
                Visit Project
              </motion.a>
            )}
            {project.githubLink && (
              <motion.a
                href={project.githubLink}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center bg-gradient-to-r from-gray-700 to-gray-800 text-white px-6 py-3 rounded-lg font-semibold hover:from-gray-800 hover:to-gray-900 transition-all shadow-md hover:shadow-lg"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"/>
                </svg>
                View on GitHub
              </motion.a>
            )}
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

const ProjectsSection = () => {

  const { t, i18n } = useTranslation();
  
  const [selectedProject, setSelectedProject] = useState(null);

  return (
    <div className="bg-gray-100 dark:bg-gray-900 transition-colors duration-300 py-16">
      <div className="max-w-7xl mx-auto">
        <motion.h2
          className="text-4xl md:text-5xl font-bold text-center mb-3 text-gray-800 dark:text-white"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          {t("projectsTitle")}
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 px-4">
          {projects.map((project) => (
            <ProjectCard
              key={project.id}
              project={project}
              onClick={setSelectedProject}
            />
          ))}
        </div>
      </div>
      <AnimatePresence>
        {selectedProject && (
          <ProjectModal
            project={selectedProject}
            onClose={() => setSelectedProject(null)}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default ProjectsSection;
